import axios from 'axios';
import { globalConfig } from 'configs/globalConfig';
// eslint-disable-next-line no-restricted-imports
import dayjs from 'global/dayjsExtended';
import { getAuthToken } from 'utils/auth';
import { biToken, apiHost, apiGwConfig } from './api.config';
import { StatisticReportApi } from './portalApis/Statistics/Statistics.apiClient';
import { ApiGetBiTokenType, ApiBiToken } from './portalApis/Statistics/Statistics.types';
import { getEnabledFeatureFlagsSerialized } from 'utils/featureFlag';

const axiosApiGw = axios.create({
    headers: {
        'X-Feature-Toggles': getEnabledFeatureFlagsSerialized(),
    },
});

const axiosBiApi = axios.create();

const getBiTokenType = (): ApiGetBiTokenType => {
    const isStudioView = globalConfig?.viewType === 'studio';
    return isStudioView ? 'studio' : 'model';
};

axiosApiGw.interceptors.request.use(async (config) => {
    const token = await getAuthToken();
    const newConfig = { ...config };
    if (newConfig.headers) newConfig.headers.Authorization = `Bearer ${token.accessToken}`;
    return newConfig;
});

const statisticReportApi = new StatisticReportApi(apiGwConfig, apiHost, axiosApiGw);
let currentBiToken: ApiBiToken | undefined = undefined;

const fetchBiToken = async (biTokenType: ApiGetBiTokenType): Promise<ApiBiToken> => {
    const result = await statisticReportApi.getBiToken(biTokenType);
    const { token } = result.data?.data || {};
    if (!token?.token?.length || !token?.expireAt?.length) {
        throw new Error('Missing required response data');
    }
    return Promise.resolve(token);
};

const ensureValidBiToken = async () => {
    // Default to the token from global-config
    if (!currentBiToken) {
        currentBiToken = biToken;
    }
    // Check if our current token is expired
    const tokenExpiresAt = dayjs(currentBiToken.expireAt);
    if (dayjs().isAfter(tokenExpiresAt)) {
        // Fetch a new token
        const biTokenType = getBiTokenType();
        currentBiToken = await fetchBiToken(biTokenType);
    }
    return currentBiToken.token;
};

axiosBiApi.interceptors.request.use(async (config) => {
    const newConfig = { ...config };
    const token = await ensureValidBiToken();

    if (newConfig.headers) {
        newConfig.headers.Authorization = `Bearer ${token}`;
    }
    return newConfig;
});

axiosBiApi.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (
            error.response.status === 403 &&
            error.response.data?.message === 'TokenExpiredError' &&
            !originalRequest._isRetry
        ) {
            originalRequest._isRetry = true;
            const biTokenType = getBiTokenType();
            currentBiToken = await fetchBiToken(biTokenType);
            return axiosBiApi(originalRequest);
        }
        return Promise.reject(error);
    },
);

export { axiosApiGw, axiosBiApi };
