// "The Module Formerly Known as Notification" - but the name was already taken
import React, { FC } from 'react';
import classnames from 'classnames';
import Icon, { IconList } from 'components/Icon';
import SmartIcon from 'components/SmartIcon';
import { useTheme } from 'contexts/ThemeContext';
import is from 'utils/is';
import styles from './Toast.module.scss';

type ToastIconTypes = 'success' | 'warning' | 'error' | 'birthday';

export interface ToastProps {
    isOpen: boolean;
    id: string;
    content: string | string[];
    onCloseClick?: (id: string) => void;
    hasDismiss?: boolean;
    icon?: ToastIconTypes;
    className?: string;
}

const icons: Record<ToastIconTypes, JSX.Element> = {
    success: <Icon icon={IconList.checkCircularSolid} className={styles.successIcon} />,
    warning: <Icon icon={IconList.warningCircularSolid} className={styles.warningIcon} />,
    error: <Icon icon={IconList.closeCircularSolid} className={styles.errorIcon} />,
    birthday: <Icon icon={IconList.cakeSolid} />,
};

const Toast: FC<ToastProps> = ({ isOpen, id, content, onCloseClick, hasDismiss, icon, className }) => {
    const { theme } = useTheme();
    if (!isOpen) return null;

    const handleCloseClick = () => {
        if (!onCloseClick) return;

        onCloseClick(id);
    };

    const getContent = (): JSX.Element => {
        if (is.array(content)) {
            return content.length > 1 ? getListContent(content) : getStringContent(content[0]);
        }
        return getStringContent(content as string);
    };

    const getListContent = (content: string[]): JSX.Element => {
        return (
            <div className={styles.messageList}>
                <ul>
                    {content.map((content) => {
                        return <li key={content}>{content}</li>;
                    })}
                </ul>
            </div>
        );
    };

    const getStringContent = (content: string): JSX.Element => {
        return <div className={styles.message} dangerouslySetInnerHTML={{ __html: content }} />;
    };

    return content ? (
        <div className={classnames(styles.toastContainer, styles[theme], styles.root)}>
            <div className={`${styles.toast} ${className}`} data-testid="toast">
                <div className={`${styles.innerWrapper} toastInner`}>
                    <div className={styles.toastContent} data-testid="toast-contents">
                        {icon && icons[icon] ? icons[icon] : null}
                        {getContent()}
                    </div>
                    {hasDismiss ? (
                        <button className={styles.closeBtn} onClick={handleCloseClick} data-testid="close-toast">
                            <SmartIcon icon={IconList.closeOutline} iconActive={IconList.closeSolid} />
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    ) : null;
};

export default Toast;
