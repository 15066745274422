const toCamelCase = (value: string | undefined, lowerCamelCase = true): string => {
    if (!value) {
        return '';
    }

    return (
        value
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+\d*|\b)|[A-Z]?[a-z]+\d*|[A-Z]|\d+/g)
            ?.map((x, idx) => {
                if (lowerCamelCase && idx === 0) {
                    return x.toLowerCase();
                }

                return x.substring(0, 1).toUpperCase() + x.substring(1);
            })
            .join('') ?? ''
    );
};

const toKebabCase = (...values: string[]): string | undefined => {
    if (!values || !values.length) {
        return '';
    }

    return values
        .filter((v) => v !== null && v !== undefined)
        .join(' ')
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+\d*|\b)|[A-Z]?[a-z]+\d*|[A-Z]|\d+/g)
        ?.map((x) => x.toLowerCase())
        ?.join('-');
};

export default {
    toCamelCase,
    toKebabCase,
};
