import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ModelReferralReferrerStatistics,
    ModelReferralSharesResponse,
} from 'domains/ModelReferral/ModelReferral.types';
import { ModelReferralState } from './ModelReferral.types';

const initialState: ModelReferralState = {
    isLoading: true,
    errorMessage: '',
};

const modelReferralSlice = createSlice({
    name: 'ModelReferral',
    initialState,
    reducers: {
        fetchModelReferralStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = '';
            state.info = undefined;
        },
        fetchModelReferralSucceeded: (state, action: PayloadAction<ModelReferralSharesResponse>) => {
            state.isLoading = false;
            state.errorMessage = '';
            state.info = action.payload;
        },
        fetchModelReferralStatsSucceeded: (state, action: PayloadAction<ModelReferralReferrerStatistics>) => {
            state.isLoading = false;
            state.errorMessage = '';
            state.stats = action.payload;
        },
        fetchModelReferralFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
    },
});

export const {
    fetchModelReferralStarted,
    fetchModelReferralSucceeded,
    fetchModelReferralFailed,
    fetchModelReferralStatsSucceeded,
} = modelReferralSlice.actions;
export default modelReferralSlice.reducer;
