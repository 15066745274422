import React, { FC } from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import { useTheme } from 'contexts/ThemeContext';
import content from './CookieConsentBanner.content';
import styles from './CookieConsentBanner.module.scss';

interface CookieConsentBannerProps {
    onAcceptClick: () => void;
    onRejectClick: () => void;
    onManageClick: () => void;
    loading?: boolean;
}

const CookieConsentBanner: FC<CookieConsentBannerProps> = (props) => {
    const { onAcceptClick, onRejectClick, onManageClick, loading } = props;
    const { theme } = useTheme();

    return (
        <div
            data-testid="cookie-consent-banner"
            className={classnames(styles.cookieBanner, styles.root, styles[theme])}
        >
            <div className={styles.meta}>
                <h6 className={styles.header}>{content.title}</h6>
                <p className={styles.body}>{content.message}</p>
            </div>
            <div className={styles.accept}>
                <Button
                    onClick={onAcceptClick}
                    data-testid="cookie-consent-banner-accept"
                    disabled={loading}
                    className={styles.button}
                >
                    {content.accept}
                </Button>
            </div>
            <div className={styles.reject}>
                <Button
                    onClick={onRejectClick}
                    data-testid="cookie-consent-banner-reject"
                    disabled={loading}
                    className={styles.button}
                >
                    {content.reject}
                </Button>
            </div>
            <div className={styles.manage}>
                <Button
                    onClick={onManageClick}
                    data-testid="cookie-consent-banner-manage"
                    disabled={loading}
                    type="textPrimary"
                    className={styles.button}
                >
                    {content.manage}
                </Button>
            </div>
        </div>
    );
};

export type { CookieConsentBannerProps };
export default CookieConsentBanner;
