import { GameTypeEnum } from '../Awards/Awards.types';

export interface PeriodStatistic {
    data: Array<AwardStats>;
}

export interface Periods {
    data: Array<AwardsStatisticsPeriod>;
}

export interface PeriodsRequest {
    performerId: number;
    performerRegistrationDate: Date;
    filterTypes?: Array<GameTypeEnum>;
}

export interface PeriodStatisticRequest {
    pageLimit: number;
    performerId: number;
    gameStatus?: 'active' | 'finished';
    expiresFrom: Date;
    expiresTo: Date;
    filterType?: GameTypeEnum;
    filterTypes?: Array<GameTypeEnum>;
}

export interface AwardsStatisticsPeriod {
    periodStart: string;
    periodEnd: string;
    key: string;
}

export interface AwardStats {
    type: AwardsStatsType;
    place: number;
    point: number;
    earning: number;
}

export enum AwardsStatsType {
    GIRL = 'Girl',
    BOY = 'Boy',
    TRANS = 'Trans',
    COUPLE = 'Couple',
    LESBIAN = 'Lesbian',
    MILF = 'Milf',
    ASIAN = 'Asian',
    FETISH = 'Fetish',
    SOULMATE = 'SoulMate',
    HOTFLIRT = 'HotFlirt',
    FREESHOW = 'FreeShow',
    NEWBIE = 'Newbie',
    REALGIFTS = 'RealGifts',
    VIDEO_CALL = 'videoCall',
    MESSENGER = 'messenger',
    MY_STORY = 'myStory',
    NEW_COMER = 'newComer',
    PICTURE_GAME = 'PicturesGame',
    VIDEO_GAME = 'VideosGame',
    HIGHLIGHTS = 'highlights',
    SUBSCRIPTION = 'subscription',
    SURPRISES = 'surprises',
    ACTIONS = 'actions',
    HOT_SHOW = 'hotShow',
    BATTLE = 'battle',
    INTERACTIVE_TOY = 'interactiveToy',
    TIPS = 'tips',
    GOODIE_BASKET = 'goodieBasket',
    FAN_CLUB = 'fanClub',
    VIDEO_CALL_CONVERSION = 'VideoCallConversion',
    FREE_CHAT_CONVERSION = 'FreeChatConversion',
    HOT_DEAL_CONVERSION = 'HotDealConversion',
    VIP_SHOW_CONVERSION = 'VIPShowConversion',
    TEASER_CONVERSION = 'TeaserConversion',
    FREE_SHORTS = 'FreeShorts',
    PREMIUM_SHORTS = 'PremiumShorts',
    PUBLIC_LOCATIONS = 'PublicLocations',
    PERSONAL_SPACE = 'PersonalSpace',
    NATURE = 'Nature',
    WELLNESS = 'Wellness',
    RISING_STAR = 'RisingStar',
}
