import React from 'react';
import _ from '@dh-fe/translate';
import type { CookieCategory } from 'domains/CookieConsent/CookieConsent.types';
import { cookieConsentService } from 'domains/CookieConsent/CookieConsent.serviceFactory';
import useAppDispatch from 'store/hooks/useAppDispatch';
import useAppSelector from 'store/hooks/useAppSelector';
import { createCookieConsent, fetchCookieConsentConfiguration } from 'store/slices/CookieConsent/CookieConsent.thunks';
import {
    selectCookieConsentConfiguration,
    selectCookieConsentConfigurationRequestStatus,
    selectCreateCookieConsentRequestStatus,
} from 'store/slices/CookieConsent/CookieConsent.selectors';
import { getFeatureFlag } from 'utils/featureFlag';
import cookies, { cookieNameCreator } from 'utils/storage/cookies';
import { FeatureFlagEnum } from 'configs/featureFlags';
import useToast from 'hooks/useToast';
import CookieConsentBanner from './components/CookieConsentBanner';
import type { CookieConsentSelections, CookieConsentUserSettings, CookieConsentViewState } from './CookieConsent.types';
import CookieConsentDialog from './components/CookieConsentDialog';
import { getUnconsentedCookieCategories, getUnknownCookies, isCookieConsentSettings } from './CookieConsent.utils';

const COOKIE_CONSENT_KEY = cookieNameCreator('essential', 'cookieConsent');
const consentedCookies = cookies.getTypeGuarded<CookieConsentUserSettings>(COOKIE_CONSENT_KEY, isCookieConsentSettings);

const CookieConsentContainer = () => {
    const isUnknownCookieCollectionEnabled = getFeatureFlag(FeatureFlagEnum.EnableUnknownCookieCollection);
    const [viewState, setViewState] = React.useState<CookieConsentViewState>(!consentedCookies ? 'banner' : 'initial');
    const [selections, setSelections] = React.useState<CookieConsentSelections>({
        analytical: consentedCookies?.categories.analytical || false,
        functionality: consentedCookies?.categories.functionality || false,
    });
    const toast = useToast();
    const dispatch = useAppDispatch();
    const cookieConsentConfiguration = useAppSelector(selectCookieConsentConfiguration);
    const cookieConsentConfigurationRequestStatus = useAppSelector(selectCookieConsentConfigurationRequestStatus);
    const createCookieConsentRequestStatus = useAppSelector(selectCreateCookieConsentRequestStatus);

    const saveCookieConsent = React.useCallback(
        (cookieCategories: CookieCategory[]) => {
            if (!cookieConsentConfiguration) return;

            dispatch(createCookieConsent(cookieCategories, cookieConsentConfiguration.id));
        },
        [dispatch, cookieConsentConfiguration],
    );

    React.useEffect(() => {
        dispatch(fetchCookieConsentConfiguration());
    }, [dispatch]);

    React.useEffect(() => {
        if (cookieConsentConfigurationRequestStatus === 'resolved') {
            if (!consentedCookies) {
                setViewState('banner');
                return;
            }

            const unconsentedCookieCategories = getUnconsentedCookieCategories(
                cookieConsentConfiguration,
                consentedCookies,
            );

            if (unconsentedCookieCategories.length > 0) {
                setViewState('banner');
                return;
            }
        }

        if (cookieConsentConfigurationRequestStatus === 'rejected') {
            setViewState('initial');
        }
    }, [cookieConsentConfigurationRequestStatus, consentedCookies]);

    React.useEffect(() => {
        if (createCookieConsentRequestStatus === 'resolved') {
            const userSettings: CookieConsentUserSettings = {
                updatedAt: new Date().toISOString(),
                categories: { ...selections, essential: true },
            };
            cookies.set(COOKIE_CONSENT_KEY, JSON.stringify(userSettings), 60 * 60 * 24 * 365);
            setViewState('consented');
        }

        if (createCookieConsentRequestStatus === 'rejected') {
            toast({
                message: _`Something went wrong. Please try again.`,
                icon: 'error',
            });
        }
    }, [createCookieConsentRequestStatus, selections]);

    React.useEffect(() => {
        if (cookieConsentConfiguration) {
            const { cookies, defaultCheckedCookieCategories, id } = cookieConsentConfiguration;

            setSelections({
                analytical: defaultCheckedCookieCategories.includes('analytical'),
                functionality: defaultCheckedCookieCategories.includes('functionality'),
            });

            if (isUnknownCookieCollectionEnabled) {
                cookieConsentService.createCookieConsentConfigurationCookies(id, getUnknownCookies(cookies));
            }
        }
    }, [cookieConsentConfiguration, isUnknownCookieCollectionEnabled]);

    const handleAcceptClick = () => {
        saveCookieConsent(['analytical', 'functionality']);
    };

    const handleRejectClick = () => {
        saveCookieConsent([]);
    };

    const handleManageClick = () => {
        setViewState('dialog');
    };

    const handleToggleClick = (category: CookieCategory, isEnabled: boolean) => {
        setSelections((prevSelections) => ({
            ...prevSelections,
            [category]: isEnabled,
        }));
    };

    const handleConfirmClick = () => {
        const selectionKeys = Object.keys(selections) as CookieCategory[];
        const consentedCookies = selectionKeys.filter((key) => selections[key]);

        saveCookieConsent(consentedCookies);
    };

    if (viewState === 'initial' || viewState === 'consented') return null;

    if (viewState === 'banner') {
        return (
            <CookieConsentBanner
                onAcceptClick={handleAcceptClick}
                onRejectClick={handleRejectClick}
                onManageClick={handleManageClick}
                loading={createCookieConsentRequestStatus === 'pending'}
            />
        );
    }

    return (
        <CookieConsentDialog
            selections={selections}
            onToggleClick={handleToggleClick}
            onConfirmClick={handleConfirmClick}
            loading={createCookieConsentRequestStatus === 'pending'}
        />
    );
};

export default CookieConsentContainer;
