import { AppThunk } from 'store/store';
import is from 'utils/is';
import { sleep } from 'utils/timers';
import { ToastContent, addToast, deleteToast } from './Toast.slice';
import config from './Toast.config';

export const showToast =
    (toast: ToastContent, autoHide?: number | boolean): AppThunk =>
    async (dispatch) => {
        const id = getRandomId();

        // By default, toast will auto-hide after default number of seconds
        let hideAfterMs: number | undefined = config.defaultHideTimeout;
        if (is.number(autoHide)) {
            // Different timeout value was provided, so use this timeout instead.
            hideAfterMs = autoHide;
        } else if (autoHide === false) {
            // Auto-hide was disabled.
            hideAfterMs = undefined;
        }

        // default isDismissable to true if not set manually
        const finalToast: ToastContent = {
            ...toast,
            isDismissable: toast.isDismissable === undefined ? true : toast.isDismissable,
        };

        dispatch(
            addToast({
                id,
                toast: finalToast,
            }),
        );
        if (hideAfterMs !== undefined) {
            await sleep(hideAfterMs);
            dispatch(deleteToast({ id }));
        }
    };

// If showToast is fired two times immediately it should return different unique ID's
const getRandomId = (): string => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};
