import React, { ChangeEventHandler, FC, MouseEvent } from 'react';
import classnames from 'classnames';
import sendGaEvent from 'utils/ga';
import { useTheme } from 'contexts/ThemeContext';
import { TestAutomationProps } from 'contracts/components';
import styles from './Button.module.scss';

export type ButtonType =
    | 'primary'
    | 'secondary1'
    | 'secondary2'
    | 'textPrimary'
    | 'textSecondary1'
    | 'textSecondary2'
    | 'linkPrimary'
    | 'linkSecondary1'
    | 'linkSecondary2'
    | 'minimal';
export type ButtonSize = 'xs' | 's' | 'm' | 'l' | 'xl';

interface ButtonProps extends Partial<TestAutomationProps> {
    type: ButtonType;
    size: ButtonSize;
    sizeMobile?: ButtonSize;
    htmlType: 'button' | 'submit' | 'reset';
    className: string;
    disabled: boolean;
    href: string;
    target: string;
    rel: string;
    before: React.ReactNode;
    after: React.ReactNode;
    onClick(event: MouseEvent): void;
    onChangeFile?: ChangeEventHandler<HTMLInputElement>;
    onDoubleClick(event: MouseEvent): void;
    eventCategory?: string;
    eventLabel?: string;
    children: React.ReactNode;
}

const textButtonTypes = [
    'textPrimary',
    'textSecondary1',
    'textSecondary2',
    'linkPrimary',
    'linkSecondary1',
    'linkSecondary2',
];

const Button: FC<Partial<ButtonProps>> = ({
    children,
    type = 'primary',
    size = 'm',
    sizeMobile,
    href,
    target,
    rel,
    htmlType = href ? undefined : 'button',
    disabled = false,
    className = '',
    before,
    after,
    onClick,
    onChangeFile,
    onDoubleClick,
    eventCategory,
    eventLabel,
    dataTestId,
    ...restProps
}) => {
    const { theme } = useTheme();
    const TagName = href ? 'a' : 'button';
    const hasTextAppearance = textButtonTypes.includes(type.toString());

    const handleOnClick = (event: MouseEvent): void => {
        if (disabled) {
            return;
        }

        if (href && eventCategory && eventLabel && window.dataLayer) {
            sendGaEvent({
                category: eventCategory,
                label: eventLabel,
                interaction: true,
            });
        }

        if (onClick) {
            onClick(event);
        }
    };

    return (
        <TagName
            data-testid={dataTestId}
            href={href}
            className={classnames(className, styles.base, styles.button, styles[size], styles[theme], styles[type], {
                [styles[`mobile-${sizeMobile}`]]: sizeMobile,
                [styles.textBase]: hasTextAppearance,
                [styles.minimal]: type === 'minimal',
                [styles.disabled]: disabled,
            })}
            type={htmlType}
            onClick={handleOnClick}
            onDoubleClick={onDoubleClick}
            target={target}
            rel={rel}
            disabled={disabled}
            {...restProps}
        >
            <>
                {before}
                {children}
                {after}
                {onChangeFile && !disabled ? <input type="file" onChange={onChangeFile} /> : null}
            </>
        </TagName>
    );
};

export default React.memo(Button);
