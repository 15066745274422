import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import useAppDispatch from 'store/hooks/useAppDispatch';
// eslint-disable-next-line no-restricted-imports
import { ToastContent } from 'store/slices/Toast/Toast.slice';
// eslint-disable-next-line no-restricted-imports
import { showToast } from 'store/slices/Toast/Toast.thunks';

const useToast = () => {
    const dispatch = useAppDispatch();

    const toast = useCallback(
        (content: ToastContent, autoHide?: number | boolean) => {
            dispatch(showToast(content, autoHide));
        },
        [dispatch],
    );

    return toast;
};

export default useToast;
