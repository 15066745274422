import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SpendingMember } from 'domains/Member/Member.types';
import { MembersState } from './Members.types';

export const initialState: MembersState = {
    topPayingMembers: {
        performerId: undefined,
        status: 'initial',
        errorMessage: undefined,
        topPayingMembers: [],
    },
    spendingMemberHistory: {
        status: 'initial',
        memberName: '',
        transactions: {},
        pagination: {
            total: 0,
            offset: 1,
            limit: 30,
        },
        totalIncome: null,
    },
};

const membersSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
        fetchTopPayingMembersStarted: (state, action: PayloadAction<number>) => {
            state.topPayingMembers.status = 'pending';
            state.topPayingMembers.performerId = action.payload;
            state.topPayingMembers.errorMessage = undefined;
        },
        fetchTopPayingMembersSucceeded: (state, action: PayloadAction<SpendingMember[]>) => {
            state.topPayingMembers.status = 'resolved';
            state.topPayingMembers.topPayingMembers = action.payload;
        },
        fetchTopPayingMembersFailed: (state, action: PayloadAction<string>) => {
            state.topPayingMembers.status = 'rejected';
            state.topPayingMembers.errorMessage = action.payload;
        },
        fetchSpendingMemberHistoryStarted: (state) => {
            state.spendingMemberHistory.status = 'pending';
            state.spendingMemberHistory.errorMessage = undefined;
        },
        fetchSpendingMemberHistorySucceeded: (
            state,
            action: PayloadAction<{
                memberName: string;
                transactions: {};
                pagination: { total: number; limit: number };
            }>,
        ) => {
            state.spendingMemberHistory.status = 'resolved';
            state.spendingMemberHistory.memberName = action.payload.memberName;
            state.spendingMemberHistory.transactions = action.payload.transactions;
            state.spendingMemberHistory.pagination.total = action.payload.pagination.total;
            state.spendingMemberHistory.pagination.limit = action.payload.pagination.limit;
        },
        fetchSpendingMemberHistoryFailed: (state, action: PayloadAction<string>) => {
            state.spendingMemberHistory.status = 'rejected';
            state.spendingMemberHistory.errorMessage = action.payload;
            state.spendingMemberHistory.transactions = {};
            state.spendingMemberHistory.pagination.total = 0;
        },
        fetchSpendingMemberTotalIncomeSucceeded: (state, action: PayloadAction<{ totalIncome: number }>) => {
            state.spendingMemberHistory.totalIncome = action.payload.totalIncome;
        },
        fetchSpendingMemberTotalIncomeFailed: (state) => {
            state.spendingMemberHistory.totalIncome = null;
        },
    },
});

export const {
    fetchTopPayingMembersStarted,
    fetchTopPayingMembersSucceeded,
    fetchTopPayingMembersFailed,
    fetchSpendingMemberHistoryStarted,
    fetchSpendingMemberHistorySucceeded,
    fetchSpendingMemberHistoryFailed,
    fetchSpendingMemberTotalIncomeSucceeded,
    fetchSpendingMemberTotalIncomeFailed,
} = membersSlice.actions;
export default membersSlice.reducer;
