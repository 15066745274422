export enum FeatureFlagEnum {
    EnableStatisticsNewCategories = 'enableStatisticsNewCategories',
    IncludeAwardsTestAccounts = 'includeAwardsTestAccounts',
    IncludeAchievementTestAccounts = 'includeAchievementTestAccounts',
    IncludeTestAccounts = 'includeTestAccounts',
    EnableVibratoys = 'enableVibratoys',
    EnableLJVibratoys = 'enableLJVibratoys',
    EnableFanclubEarning = 'enableFanclubEarning',
    MockCertifiedStudioDataOnDev = 'mockCertifiedStudioDataOnDev',
    MockAllNotifications = 'mockAllNotifications',
    EnablePayoutRequests = 'enablePayoutRequests',
    EnableWillingnessPhoto = 'enableWillingnessPhoto',
    ForceSsoGoOnlineHost = 'forceSsoGoOnlineHost',
    EnableMastercardConsent = 'enableMastercardConsent',
    ForceAu10TixIdVerification = 'forceAu10TixIdVerification',
    DisableStoryAutoApprovalForTestAccounts = 'disableStoryAutoApprovalForTestAccounts',
    EnableUnknownCookieCollection = 'enableUnknownCookieCollection',
    EnableSupportChatJWT = 'enableSupportChatJWT',
    EnableReduxDevTools = 'enableReduxDevTools',
    ForceEmarsys = 'forceEmarsys',
    EnableKiirooToy = 'enableKiirooToy',
    EnableHindi = 'enableHindi',
    ForceShowBoyCategory = 'forceShowBoyCategory',
}
