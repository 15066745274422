import React, { FC } from 'react';
// eslint-disable-next-line no-restricted-imports
import { deleteToast } from 'store/slices/Toast/Toast.slice';
import useAppSelector from 'store/hooks/useAppSelector';
import useAppDispatch from 'store/hooks/useAppDispatch';
import Toast from './Toast';

const ToastsContainer: FC = () => {
    const dispatch = useAppDispatch();
    const { toasts } = useAppSelector((state) => state.toast);

    const handleCloseClick = (id: string) => {
        dispatch(deleteToast({ id }));
    };

    return (
        <>
            {Object.entries(toasts).map(([id, toast]) => (
                <Toast
                    key={id}
                    content={toast.message}
                    icon={toast.icon}
                    isOpen
                    onCloseClick={handleCloseClick}
                    id={id}
                    hasDismiss={toast.isDismissable}
                />
            ))}
        </>
    );
};

export default ToastsContainer;
