import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { MessengerMember, MessengerMemberLastMessage, LoyaltyLevelEnum } from 'domains/Messenger/Messages.types';
import { FilterResultCount, GetThreadsResponse, ThreadsOrder } from 'domains/Messenger/Messenger.types';
// eslint-disable-next-line no-restricted-imports
import { JawsMessageResponse, CommonJawsMessage } from 'pages/Messenger/utils/types';
import { updateThreadsWithJAWS, findThreadIndex } from './Threads.utils';
import { ThreadsState, ThreadFilters } from './Threads.types';

export const threadsInitialState: ThreadsState = {
    filter: {
        searchTerm: '',
        activeFilter: undefined,
        resultsCount: {
            unread: 0,
            unanswered: 0,
            pinned: 0,
            deleted: 0,
        },
        requestStatus: 'initial',
    },
    entries: [],
    isLoading: false,
    isInitialLoadDone: false,
    errorMessage: null,
    meta: {
        allThreadCount: 0,
        unreadThreadCount: 0,
    },
    order: 'desc',
};

interface ThreadMessageReceivedProps {
    data: JawsMessageResponse<CommonJawsMessage>;
    activeThread: number;
}

const threadsSlice = createSlice({
    name: 'messenger',
    initialState: threadsInitialState,
    reducers: {
        addThread: (state, action: PayloadAction<MessengerMember>) => {
            state.entries.unshift(action.payload);
        },
        createThreadStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = null;
        },
        createThreadSucceeded: (state, action: PayloadAction<{ thread: MessengerMember; tempThreadId: number }>) => {
            const { tempThreadId, thread } = action.payload;
            const index = findThreadIndex(state.entries, tempThreadId);

            state.entries[index] = thread;
            state.isLoading = false;
        },
        createThreadFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        fetchThreadsStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = null;
            state.meta = threadsInitialState.meta;
        },
        fetchThreadsSucceeded: (state, action: PayloadAction<GetThreadsResponse>) => {
            const { threads, meta } = action.payload;

            state.isLoading = false;
            state.isInitialLoadDone = true;
            state.entries = threads;
            state.meta = meta;
        },
        fetchThreadsNextPageSucceeded: (state, action: PayloadAction<GetThreadsResponse>) => {
            const { threads, meta } = action.payload;

            state.isLoading = false;
            state.isInitialLoadDone = true;
            state.entries = [...state.entries, ...threads];
            state.meta = meta;
        },
        updateThreadEntries: (state, action: PayloadAction<MessengerMember[]>) => {
            state.entries = action.payload;
        },
        fetchThreadsFailed: (state, action: PayloadAction<string>) => {
            state.entries = [];
            state.isLoading = false;
            state.isInitialLoadDone = true;
            state.errorMessage = action.payload;
        },
        deleteThreadStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = null;
        },
        deleteThreadSucceeded: (state, action: PayloadAction<number>) => {
            const deletedEntry = state.entries.find((entry) => entry.id === action.payload);
            const updatedEntries = state.entries.filter((entry) => entry.id !== action.payload);
            const currentUnreadThreadCount = state.meta?.unreadThreadCount || 0;
            const deletedEntryUnreadMessageCount = deletedEntry?.unreadMessageCount || 0;
            const newUnreadThreadCount = currentUnreadThreadCount - deletedEntryUnreadMessageCount;
            const currentAllThreadCount = state?.meta?.allThreadCount || 0;
            const newAllThreadCount = currentAllThreadCount - deletedEntryUnreadMessageCount;

            state.isLoading = false;
            state.entries = updatedEntries;
            state.meta = {
                allThreadCount: newAllThreadCount,
                unreadThreadCount: newUnreadThreadCount,
            };
        },
        deleteThreadFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        threadMessageReceived: (state, action: PayloadAction<ThreadMessageReceivedProps>) => {
            const updatedThreads = updateThreadsWithJAWS(state.entries, action.payload);

            state.entries = updatedThreads;
        },
        setThreadAsReadSucceeded: (state, action: PayloadAction<{ threadId: number }>) => {
            const thread = state.entries.find((thread) => thread.id === action.payload.threadId);

            if (thread) thread.unreadMessageCount = 0;
        },
        updateThreadTabNotificationsSucceeded: (state, action: PayloadAction<number>) => {
            state.meta = {
                allThreadCount: state?.meta?.allThreadCount || 0,
                unreadThreadCount: action.payload,
            };
        },
        updateThreadLastMessageSucceeded: (
            state,
            action: PayloadAction<{
                threadId: number;
                lastMessage: MessengerMemberLastMessage;
                lastMessageTimeStamp: string;
            }>,
        ) => {
            const { threadId, lastMessageTimeStamp, lastMessage } = action.payload;
            const thread = state.entries.find((thread) => thread.id === threadId);

            if (!thread) return;

            thread.lastMessage = lastMessage;
            thread.lastMessageTimeStamp = lastMessageTimeStamp;
        },
        updateThreadsOrderSucceeded: (state, action: PayloadAction<{ threadId: number }>) => {
            const { threadId } = action.payload;
            state.entries.sort((x, y) => {
                if (x.id === threadId) {
                    return -1;
                }
                return y.id === threadId ? 1 : 0;
            });
        },
        updateLoyaltyLevelSucceeded: (
            state,
            action: PayloadAction<{ loyaltyLevel: LoyaltyLevelEnum | null; threadId: number }>,
        ) => {
            const { loyaltyLevel, threadId } = action.payload;

            const entry = state.entries.find((entry: Draft<MessengerMember>) => entry.id === threadId);

            if (!entry) {
                throw new Error('Entry not found');
            }

            entry.partner.loyaltyLevel = loyaltyLevel;
        },
        updateActiveFilter: (state, action: PayloadAction<ThreadFilters | undefined>) => {
            state.filter.activeFilter = action.payload;
        },
        pinThreadSucceeded: (state, action: PayloadAction<number>) => {
            const thread = state.entries.find((thread) => thread.id === action.payload);
            if (thread) thread.isPinned = true;
        },
        unpinThreadSucceeded: (state, action: PayloadAction<number>) => {
            const thread = state.entries.find((thread) => thread.id === action.payload);
            if (thread) thread.isPinned = false;
        },
        markThreadAsRestored: (state, action: PayloadAction<number>) => {
            const thread = state.entries.find((thread) => thread.id === action.payload);
            if (thread) thread.isRestored = true;
        },
        updateSearchTermValue: (state, action: PayloadAction<string>) => {
            state.filter.searchTerm = action.payload;
        },
        updateFilterResultsCountStarted: (state) => {
            state.filter.requestStatus = 'pending';
        },
        updateFilterResultsCountSucceeded: (state, action: PayloadAction<FilterResultCount>) => {
            state.filter.resultsCount = action.payload;
            state.filter.requestStatus = 'resolved';
        },
        updateFilterResultsCountFailed: (state) => {
            state.filter.resultsCount = {
                unanswered: 0,
                unread: 0,
                pinned: 0,
                deleted: 0,
            };
            state.filter.requestStatus = 'rejected';
        },
        removeThreadById: (state, action: PayloadAction<number>) => {
            state.entries = state.entries.filter((entry) => entry.id !== action.payload);
        },
        threadRestoreStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = null;
        },
        threadRestoreSucceeded: (state) => {
            state.isLoading = false;
            state.errorMessage = null;
        },
        threadRestoreFailed: (state) => {
            state.isLoading = false;
        },
        changeThreadsOrder: (state, action: PayloadAction<ThreadsOrder>) => {
            state.order = action.payload;
        },
    },
});

export const {
    createThreadStarted,
    createThreadSucceeded,
    createThreadFailed,
    fetchThreadsStarted,
    fetchThreadsSucceeded,
    fetchThreadsNextPageSucceeded,
    fetchThreadsFailed,
    deleteThreadStarted,
    deleteThreadSucceeded,
    deleteThreadFailed,
    threadMessageReceived,
    addThread,
    updateThreadTabNotificationsSucceeded,
    updateThreadLastMessageSucceeded,
    updateThreadsOrderSucceeded,
    updateLoyaltyLevelSucceeded,
    setThreadAsReadSucceeded,
    updateThreadEntries,
    updateActiveFilter,
    pinThreadSucceeded,
    unpinThreadSucceeded,
    markThreadAsRestored,
    updateSearchTermValue,
    updateFilterResultsCountStarted,
    updateFilterResultsCountSucceeded,
    updateFilterResultsCountFailed,
    removeThreadById,
    threadRestoreStarted,
    threadRestoreSucceeded,
    threadRestoreFailed,
    changeThreadsOrder,
} = threadsSlice.actions;
export default threadsSlice.reducer;
