import { combineReducers } from '@reduxjs/toolkit';
import performerReducer from './slices/Performer/Performer.slice';
import performerPricingReducer from './slices/Performer/PerformerPricing.slice';
import performerTwoWayAudioReducer from './slices/Performer/PerformerTwoWayAudio.slice';
import periodsReducer from './slices/Periods/Periods.slice';
import periodStatisticsReducer from './slices/PeriodStatistics/PeriodStatistics.slice';
import pretenceGroupsReducer from './slices/PretenceGroups/PretenceGroups.slice';
import persistedComponentReducer from './slices/PersistedComponent/PersistedComponent.slice';
import promoVideosReducer from './slices/PromoVideos/PromoVideos.slice';
import promoPeriodTimeReducer from './slices/PromoPeriodTime/PromoPeriodTime.slice';
import awardsReducer from './slices/Awards/Awards.slice';
import awardsStatisticsReducer from './slices/AwardsStatistics/AwardsStatistics.slice';
import awardStatisticsPeriodsReducer from './slices/AwardsStatisticsPeriods/AwardsStatisticsPeriods.slice';
import toastReducer from './slices/Toast/Toast.slice';
import downloadJasminCamReducer from './slices/DownloadJasminCam/DownloadJasminCam.slice';
import tipsReducer from './slices/Tips/Tips.slice';
import statisticsReducer from './slices/Statistics/Statistics.slice';
import latestNewsReducer from './slices/LatestNews/LatestNews.slice';
import selectionTaskListReducer from './slices/SelectionTaskList/SelectionTaskList.slice';
import messengerReducer from './slices/Messenger/Messenger.reducer';
import massMessageReducer from './slices/Messenger/MassMessage/MassMessage.slice';
import messengerTemplatesReducer from './slices/Messenger/MessengerTemplates/MessengerTemplates.slice';
import membersReducer from './slices/Members/Members.slice';
import subscriptionReducer from './slices/Subscription/Subscription.slice';
import modelBoosterReducer from './slices/ModelBooster/ModelBooster.slice';
import levelDependentReducer from './slices/LevelDependent/LevelDependent.slice';
import accountStripesReducer from './slices/AccountStripes/AccountStripes.slice';
import notificationReducer from './slices/Notification/Notification.reducer';
import modelReferralReducer from './slices/ModelReferral/ModelReferral.slice';
import actionRequestsReducer from './slices/ActionRequests/ActionRequests.slice';
import tagGroupsReducer from './slices/TagGroups/TagGroups.slice';
import musicLibraryReducer from './slices/MusicLibrary/MusicLibrary.slice';
import profilePictureReducer from './slices/ProfilePicture/ProfilePicture.slice';
import beMyFanReducer from './slices/BeMyFan/BeMyFan.slice';
import performerNewbieStatusReducer from './slices/Performer/PerformerNewbieStatus.slice';
import fanClubReducer from './slices/FanClub/FanClub.slice';
import studioReducer from './slices/Studio/Studio.slice';
import messengerGalleryReducer from './slices/Messenger/MessengerGallery/MessengerGallery.slice';
import forwardMediaReducer from './slices/Messenger/ForwardMedia/ForwardMedia.slice';
import ljStoriesReducer from './slices/LJStories/LJStories.slice';
import achievementsReducer from './slices/Achievements/Achievements.slice';
import loyalfansReducer from './slices/Loyalfans/Loyalfans.slice';
import consentReducer from './slices/Consent/Consent.slice';
import cookieConsentReducer from './slices/CookieConsent/CookieConsent.slice';
import decemberContestReducer from './slices/DecemberContest/DecemberContest.slice';
import permissionsReducer from './slices/Permissions/Permissions.slice';
import summerContestReducer from './slices/SummerContest/SummerContest.slice';
import shortsReducer from './slices/Shorts/Shorts.slice';
import topModelAcademyReducer from './slices/TopModelAcademy/TopModelAcademy.slice';

export const allReducers = {
    accountStripes: accountStripesReducer,
    achievements: achievementsReducer,
    actionRequests: actionRequestsReducer,
    awards: awardsReducer,
    awardStatistics: awardsStatisticsReducer,
    awardStatisticsPeriods: awardStatisticsPeriodsReducer,
    beMyFan: beMyFanReducer,
    consent: consentReducer,
    cookieConsent: cookieConsentReducer,
    downloadJasminCam: downloadJasminCamReducer,
    decemberContest: decemberContestReducer,
    fanClub: fanClubReducer,
    forwardMedia: forwardMediaReducer,
    latestNews: latestNewsReducer,
    levelDependent: levelDependentReducer,
    ljStories: ljStoriesReducer,
    loyalfans: loyalfansReducer,
    massMessenger: massMessageReducer,
    members: membersReducer,
    messenger: messengerReducer,
    messengerGallery: messengerGalleryReducer,
    messengerTemplates: messengerTemplatesReducer,
    modelBooster: modelBoosterReducer,
    modelReferral: modelReferralReducer,
    musicLibrary: musicLibraryReducer,
    notification: notificationReducer,
    performer: performerReducer,
    performerNewbieStatus: performerNewbieStatusReducer,
    performerPricing: performerPricingReducer,
    performerTwoWayAudio: performerTwoWayAudioReducer,
    periods: periodsReducer,
    periodStatistics: periodStatisticsReducer,
    permissions: permissionsReducer,
    persistedComponent: persistedComponentReducer,
    pretenceGroups: pretenceGroupsReducer,
    profilePictures: profilePictureReducer,
    promoPeriodTime: promoPeriodTimeReducer,
    promoVideos: promoVideosReducer,
    selectionTaskList: selectionTaskListReducer,
    shorts: shortsReducer,
    statistics: statisticsReducer,
    studio: studioReducer,
    subscription: subscriptionReducer,
    summerContest: summerContestReducer,
    tagGroups: tagGroupsReducer,
    tips: tipsReducer,
    toast: toastReducer,
    topModelAcademy: topModelAcademyReducer,
};

export const getAllReducersCombined = () => combineReducers(allReducers);

type ReducersTypes = keyof typeof allReducers;
export type AllReducerTypes = ReducersTypes[];
