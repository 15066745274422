import { LoyaltyLevelEnum } from 'domains/Messenger/Messages.types';

export interface SubscriptionStatisticsEntity {
    activeSubscriptions: number;
    allTimeSubscriptions: number;
    subscriptionEarnings: number;
}

export interface SubscriberRequest {
    performerId: number;
    subscriptionType?: 'my-story' | 'subscription';
    offset?: number;
    limit?: number;
    filter?: SubscriberRequestFilter;
    order?: SubscriberRequestOrder;
}

interface SubscriberRequestOrder {
    status?: 'asc' | 'desc';
    expirationDate?: 'asc' | 'desc';
}

export interface SubscriberRequestFilter {
    memberNick?: string;
    subscriptionStatus?: UISubscriptionFilterEnum;
}

export interface MessageTemplateEntity {
    id: number;
    name: string;
    template: string;
}

export interface SubscriptionPackageEntity {
    packageId: string;
    serviceId: string;
    currency: string;
    credit: number;
    price: number;
    isConverted: boolean;
}

export interface SubscriptionAvailabilityEntity {
    isAvailable: boolean;
    numberOfUploadedVideos?: number;
    timeLeftInSeconds?: number;
}

export enum MessageTemplateName {
    SubscriptionsMemberSubscribed = 'dh.jasmin.subscriptions.member.subscribed',
    FanclubMemberSubscribed = 'dh.jasmin.fanclub.member.subscribed',
}

export type SubscriptionStatus = 'active' | 'inactive' | 'expiring';
export type SubscriberStatus = 'enabled' | 'disabled' | 'deleted';
export type SubscriberTier = 'bronze' | 'silver' | 'gold';

export enum UISubscriptionFilterEnum {
    ALL = 'all',
    ACTIVE = 'active',
    EXPIRED = 'expired',
    EXPIRING = 'expiring',
}

export interface UISubscriber {
    id: number;
    name: string;
    highlightedName?: string;
    durationInMonths: number;
    expirationDateString: string;
    subscriberStatus: SubscriberStatus;
    subscriptionStatus: SubscriptionStatus;
    income: number;
    tier?: SubscriberTier;
    level?: LoyaltyLevelEnum;
    hasProMembership?: boolean;
}

export interface UISubscribersPagination {
    limit: number;
    offset: number;
    total: number;
}

export interface UISubscriptionStatistics extends SubscriptionStatisticsEntity {}

export interface UISubscribers {
    subscriberList: UISubscriber[];
    pagination: UISubscribersPagination;
}

export enum SubscriptionLoading {
    NONE = '',
    INITIAL = 'initial',
    SEARCH = 'search',
    FILTER = 'filter',
    LIST = 'list',
    PAGINATION = 'pagination',
}
