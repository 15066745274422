import TagManager from 'react-gtm-module';
import { AccountViewType } from 'contracts/window';
import { Environment, globalConfig } from 'configs/globalConfig';
import { getProduct } from 'configs/sites';

export interface InitGaProps {
    eventType: 'config' | 'set';
    viewType: AccountViewType | null | undefined;
    language: string;
    isLimitedAccess: boolean;
    isTestAccount: boolean;
    hasMultiplePerformers: boolean;
    authenticated: boolean;
    trafficType: Environment;
    userId: number;
}

enum Environments {
    dev = 'development',
    staging = 'staging',
    prod = 'production',
}

interface SendGaEventProps {
    category: string;
    label: string;
    action?: string;
    interaction?: boolean;
}

function gTag(...args: unknown[]): void {
    if (!window.dataLayer) {
        window.dataLayer = window.dataLayer || [];
    }

    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
}

export const initGa = ({
    eventType,
    viewType,
    language,
    isLimitedAccess,
    isTestAccount,
    hasMultiplePerformers,
    authenticated,
    trafficType,
    userId,
}: InitGaProps) => {
    const userProperties = {
        application: 'MSC Portal',
        view_type: ((!viewType || viewType === 'single') && 'model') || viewType,
        language,
        limited_access: isLimitedAccess,
        test_account: isTestAccount,
        multiple_performers: hasMultiplePerformers,
        user_account_type: viewType || 'model',
        authenticated,
        traffic_type: Environments[trafficType],
        user_id: userId,
    };

    if (eventType === 'config') {
        gTag('config', globalConfig.gtm, {
            app_name: getProduct(),
            transport_type: 'beacon',
            send_page_view: false,
            user_properties: userProperties,
        });

        gTag('event', 'page_view', {
            page_title: window.document.title,
            page_path: window.location.pathname.replace(new RegExp(`^/${language}/?`, 'i'), '/'),
            page_location: window.location.href,
            send_to: globalConfig.gtm,
        });
    } else if (eventType === 'set') {
        gTag('set', 'user_properties', userProperties);
    }
};

const sendGaEvent = ({ category, label, action, interaction }: SendGaEventProps) => {
    let dataLayer = {
        event: 'GA - Event - Generic Event',
        eventCategory: category,
        eventLabel: label,
        eventAction: typeof action === 'string' ? action : 'click',
    };

    if (typeof interaction === 'boolean') {
        dataLayer = { ...dataLayer, ...{ eventInteraction: interaction } };
    }

    TagManager.dataLayer({ dataLayer });
};

export default sendGaEvent;
